import React, { FC } from 'react';
import { Avatar, Card, List, Skeleton, Typography } from 'antd';
import moment from 'moment';
import { observer } from 'mobx-react';
import { ICaseDetailHeader } from '../../models/components/ICaseDetailHeader';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const CaseDetailHeader: FC<ICaseDetailHeader> = ({
  patientCareStore,
  showSkeleton
}: ICaseDetailHeader): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={'dtb-deail-header dtb-detail-header'}>
      <div
        className={'dtb-detail-header-split-item'}
        style={{ margin: 0, padding: 0 }}
      >
        <Card title={t('patient:patient')}>
          {showSkeleton ? (
            <>
              <br />
              <Skeleton
                loading={true}
                active
                avatar={{ size: 'default' }}
                paragraph={{ rows: 1 }}
              ></Skeleton>{' '}
            </>
          ) : (
            <>
              <List.Item.Meta
                avatar={
                  <Avatar size={'default'}>
                    {patientCareStore?.selectedCase &&
                      patientCareStore?.selectedCase?.patient?.firstName?.charAt(
                        0
                      ) +
                        patientCareStore?.selectedCase?.patient?.lastName?.charAt(
                          0
                        )}
                  </Avatar>
                }
                title={
                  <p>
                    {`
                      ${patientCareStore?.selectedCase?.patient?.firstName} 
                      ${patientCareStore?.selectedCase?.patient?.lastName}
                    `}
                  </p>
                }
                //description={item.description}
              />
              {/*t('patient:birthday'):{' '}*/}
              {patientCareStore?.selectedCase?.patient?.birthdayString
                ? moment(
                    patientCareStore?.selectedCase?.patient?.birthdayString
                  ).format('DD.MM.YYYY')
                : '-'}
              {/* + ' '}
              | Geschlecht: {patientCareStore?.selectedCase?.patient?.gender}
              */}
            </>
          )}
        </Card>
      </div>
      <div className={'dtb-detail-header-split-item'}>
        <Card title={t('patient:treatment')}>
          {showSkeleton ? (
            <>
              <br />
              <Skeleton
                title={false}
                paragraph={{ rows: 3 }}
                style={{ margin: 0, padding: 0 }}
              />
            </>
          ) : (
            <div style={{ marginTop: 10 }}>
              <Text>
                {t('patient:treatmentId')}:{' '}
                {patientCareStore?.selectedCase?.caseToken}
              </Text>
              <br />
              <Text>
                {t('patient:treatment')}:{' '}
                {patientCareStore?.selectedCase?.title}
              </Text>
              <br />
              <Text>
                {t('patient:description')}:{' '}
                {patientCareStore?.selectedCase?.description === null
                  ? '-'
                  : patientCareStore?.selectedCase?.description}
              </Text>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default observer(CaseDetailHeader);
