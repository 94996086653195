import React, { FC } from 'react';
import { Layout } from 'antd';
import PatientForm from '../../components/PatientForm/PatientForm';
import { IPatient } from '../../models/pages/IPatient';
import './../../scss/Patient.scss';
import { inject, observer } from 'mobx-react';

const Patient: FC<IPatient> = ({ patientCareStore }: IPatient): JSX.Element => {
  return (
    <Layout className="content-wrapper content-scroll-wrapper">
      <PatientForm patientCareStore={patientCareStore} />
    </Layout>
  );
};

export default inject('patientCareStore')(observer(Patient));
