import React, { FC } from 'react';
import { Typography, Row, Col, message } from 'antd';
import { IWarnings } from '../../models/data/IWarnings';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ICasesWarningBanners } from '../../models/components/ICasesWarningBanners';
import i18next from 'i18next';
import { observer } from 'mobx-react';

const { Text } = Typography;

const CasesWarningBanners: FC<ICasesWarningBanners> = ({
  entry,
  casesLoaded,
  medicationTherapyStore,
  patientCareStore,
  parentIndex
}): JSX.Element => {
  const dateFormat: string =
    i18next.language === 'de' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';

  /**
   *
   * @param index
   * @param parentIndex
   */
  const closeWarning = async (
    index: number,
    parentIndex: number,
    warningId: string,
    entry: any
  ) => {
    await patientCareStore.updateCaseItemList(index, parentIndex);
    const res = await medicationTherapyStore.ackHcpWarning(warningId);
    if (res) {
      await patientCareStore.updateCaseItemList(index, parentIndex);
      entry?.warnings.splice(index, 1);
      message.success('Erfolgreich geschlossen');
    } else {
      message.success(
        'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später nochmal.'
      );
    }
  };

  return (
    <Row className="dtb-warnings-grid">
      {casesLoaded &&
        entry?.warnings?.map((warning: IWarnings, index: number) => {
          return (
            <Col key={index} span={24} className="dtb-warnings">
              <Text style={{ color: '#fff' }}>
                <WarningOutlined className="dtb-warning-icon" />{' '}
                {moment(entry.createdAt).format(dateFormat)}{' '}
                {warning.reason.reasonObjectName}{' '}
                {warning.reason.affectedValues}
                <CloseOutlined
                  className="right icon-item dtb-warning-baneer-close"
                  onClick={() =>
                    closeWarning(index, parentIndex, warning.warningId, entry)
                  }
                />
              </Text>
            </Col>
          );
        })}
    </Row>
  );
};

export default observer(CasesWarningBanners);
