import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Tabs, Layout, Drawer, Alert } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CaseDetailSidebar from '../../components/CaseDetailSidebar/CaseDetailSidebar';
import CaseDetailHeader from '../../components/CaseDetailHeader/CaseDetailHeader';
import { ICaseDetail } from '../../models/pages/ICaseDetail';
import { useTranslation } from 'react-i18next';
import settings from '../../config/settings';
import detailsTabsTemplate from '../../templates/tabs/detailsTabsTemplate';
import { json } from 'stream/consumers';
//import '../../scss/Detail.scss';
//import './Detail.scss';

const { Content } = Layout;

const CaseDetail: FC<ICaseDetail> = ({
  uiStore,
  patientCareStore,
  medicationTherapyStore,
  organisationManagement,
  authStore,
  messagingStore,
  detailInfoVisible,
  onCloseDetail
}): JSX.Element => {
  const { tab, caseId, patientId } = useParams();
  const [loader, setLoader] = useState(true);
  const [minAnimation, setMinAnimation] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentMedplan = medicationTherapyStore.getCurrentMedplan;

  useEffect(() => {
    const { setCaseViewMobil } = uiStore;
    const { fetchCase } = patientCareStore;
    /**
     * @description needed header mobil hamburger menu right
     */
    setCaseViewMobil(true);

    const getCase = async () => {
      /**
       * @description ui min animation loader
       */
      setLoader(false);
      setMinAnimation(true);
      setInterval(async () => {
        setMinAnimation(false);
      }, settings.minAnimation);
      /**
       * @description init loading case
       */
      // case data
      const caseData = await fetchCase(caseId);
      /**
       * @description redirect to overview if url caseId or patientId incorrect with loaded case data
       */
      if (
        (caseData !== undefined && caseData.id !== caseId) ||
        (caseData !== undefined && caseData.patientId !== patientId)
      ) {
        navigate(`/`);
      }

      // load messages messaging
      if (
        caseData !== null &&
        caseData !== undefined &&
        caseData.hasOwnProperty('messageProcessId') &&
        caseData.messageProcessId !== null
      ) {
        await messagingStore.fetchUnreadMessageCount(
          caseData?.messageProcessId
        );
      }

      /**
       * @description temp BfArM disabled diagnose
       **/
      // await therapyStore.getDiagnoses(patientId);

      /**
       * @description load current medplan
       **/
      const getCurrentMedPlan = await medicationTherapyStore.fetchCurrentMedPlan(
        patientId
      );

      /**
       * @description load medication
       */
      await medicationTherapyStore.fetchMedication(getCurrentMedPlan.id);
      await medicationTherapyStore.fetchNotTakenMedication(patientId);

      if (getCurrentMedPlan?.data[0]?.length > 0) {
        /**
         * @description init loading observation lists sideeffects and vitaldata
         */
        await medicationTherapyStore.fetchAlarmtTresholdReviewDaySideEffects(
          getCurrentMedPlan.data[0][0].id,
          patientId
        );
        await medicationTherapyStore.fetchAlarmtTresholdReviewDayVitalData(
          getCurrentMedPlan.data[0][0].id,
          patientId
        );
      }
    };

    loader && getCase();

    // messaging counter polling
    const pollingCounterTimerId: any = setInterval(async () => {
      const selectedCase = patientCareStore.getCaseEntry;
      await messagingStore.fetchUnreadMessageCount(
        selectedCase?.messageProcessId
      );
    }, 10000);

    /**
     * @description polling observations data all 5min (need getter medplan because currentmedplan null )
     */
    const pollingObservationTimerId = setInterval(async () => {
      const currentMedplan = medicationTherapyStore.getCurrentMedplan;
      medicationTherapyStore.pollingSideEffectsAndVitalDataObservations(
        patientId,
        currentMedplan.id
      );
    }, 300000);

    return () => {
      /**
       * @description clear header mobil hamburger menu right
       */
      clearInterval(pollingCounterTimerId);
      clearInterval(pollingObservationTimerId);
      uiStore.setCaseViewMobil(false);
      medicationTherapyStore.setCurrentMedplan(null);
      medicationTherapyStore.setTherapyPlansList([]);
    };
  }, [
    caseId,
    loader,
    medicationTherapyStore,
    patientCareStore,
    patientId,
    messagingStore,
    uiStore
  ]);

  const onClose = () => {
    onCloseDetail();
  };

  const showSkeleton = patientCareStore.getCaseLoaded || minAnimation;
  const { userAttributes } = authStore;

  /**
   * @description tabs template
   */
  const items: {
    label: string;
    key: string;
    children: any;
  }[] = detailsTabsTemplate({
    patientCareStore,
    messagingStore,
    tab,
    t
  });

  return (
    <div className="dtb-content-wrapper-with-sidebar">
      <Content className="dtb-content ">
        {currentMedplan?.status === null && <Alert message="CORS Error" />}
        <CaseDetailHeader
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
        />
        <div
          style={{ background: '#fff', marginBottom: 14 }}
          className="about-wrapper "
        >
          <Tabs
            defaultActiveKey={tab !== null ? tab : 'monitoring'}
            onChange={(key) => {
              navigate(`/patient/${patientId}/case/${caseId}/${key}`);
            }}
            activeKey={tab}
            tabPosition="top"
            animated={{ tabPane: true, inkBar: true }}
            items={items}
          />
        </div>
      </Content>

      {!detailInfoVisible && patientId ? (
        <CaseDetailSidebar
          organisationManagement={organisationManagement}
          medicationTherapyStore={medicationTherapyStore}
          userAttributes={userAttributes}
          showSkeleton={showSkeleton}
        />
      ) : (
        <Drawer
          //title="Multi-level drawer"
          width={280}
          closable={false}
          onClose={onClose}
          visible={detailInfoVisible}
          placement={'right'}
        >
          <CaseDetailSidebar
            organisationManagement={organisationManagement}
            medicationTherapyStore={medicationTherapyStore}
            userAttributes={userAttributes}
            showSkeleton={showSkeleton}
          />
        </Drawer>
      )}
    </div>
  );
};

export default inject(
  'uiStore',
  'patientCareStore',
  'medicationTherapyStore',
  'organisationManagement',
  'authStore',
  'messagingStore'
)(observer(CaseDetail));
